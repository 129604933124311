import InavoLoading from '@/components/InavoLoading/InavoLoading'

import './index.scss'

const SuspenseLoading = () => {
  return (
    <div className='suspense-loading'>
      <InavoLoading className='suspense-loading__loading-icon' />
    </div>
  )
}

export default SuspenseLoading
