// We should add it
// eslint-disable-next-line no-unused-vars
import ApexCharts from 'apexcharts'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Suspense, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'

import SuspenseLoading from '@/components/SuspenseLoading'
import router from '@/router'

const SuspenseFallback = () => {
  return <SuspenseLoading />
}

function App() {
  useEffect(() => {
    dayjs.extend(customParseFormat)
    dayjs.locale('tr') // We should extend with other languages
  }, [])

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default App
